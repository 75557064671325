<template>
    <b-form-group>
        <label>Tipo</label>
        <multiselect
            id="type"
            label="label"
            track-by="value"
            v-model="type"
            :options="typeOptions"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="true"
            placeholder="Selecionar"
            class="with-border"
        >
            <template slot="caret">
                <div class="chevron">
                    <ChevronDown />
                </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
        <b-form-invalid-feedback :state="!validated ? null : !!type">Campo obrigatório</b-form-invalid-feedback>
    </b-form-group>
</template>
<script>
import * as TABLE from '@tables/utils/constants'

export default {
    props: {
        groupType: String,
        value: String,
        validated: Boolean,
    },
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    data() {
        return {
            type: null,
            typeOptions: TABLE.TYPE_OPTIONS
                .filter(el => !TABLE.hiddenTypes.includes(el.value))
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.type = this.typeOptions.find(option => option.value === value)
            }
        },
        type(option) {
            this.$emit('input', option.value)
            this.$emit('change', option.value)
        }
    }
}
</script>
<style lang="scss" scoped>

</style>